import React from 'react';
import styled from 'styled-components';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Tooltip from '@mui/material/Tooltip';
import PageButtonSelectHeader from './PageButtonSelectHeader';
import { useIntl } from 'react-intl';

export default function PageContent({
  children,
  relative,
  backgroundColor,
  boxShadow,
  borderRadius,
  backHandler,
  buttonHandler,
  title,
  margin,
  width,
  stage,
  setStage,
  stages,
  isStageActive,
  showSelectButton,
}) {
  const intl = useIntl();
  return (
    <Container
      relative={relative}
      backgroundColor={backgroundColor}
      boxShadow={boxShadow}
      borderRadius={borderRadius}
      backHandler={backHandler}
      margin={margin}
      width={width}
    >
      {!!backHandler && (
        <BackButton onClick={backHandler}>
          <KeyboardArrowLeftIcon />
        </BackButton>
      )}
      {!!title && (
        <Title>
          <span>{title}</span>
        </Title>
      )}
      <ButtonsContainer>
        {showSelectButton && (
          <PageButtonSelectHeader
            name={`${intl.formatMessage({ id: 'app.stage' })} ${stage?.value || 1}`}
            buttons={[
              // {
              //   name: `${intl.formatMessage({ id: 'app.stage' })} 1`,
              //   onClick: () => setStage(null),
              // },
              ...(stages || []).map((x) => {
                return {
                  name: `${intl.formatMessage({ id: 'app.stage' })} ${x.value}`,
                  onClick: () => setStage(x),
                };
              }),
            ]}
          />
        )}
        {!!buttonHandler &&
          isStageActive &&
          (buttonHandler.notValidText ? (
            <Tooltip title={buttonHandler.notValidText}>
              <ButtonContainer onClick={buttonHandler.onClick} isValid={buttonHandler.isValid}>
                <span>{buttonHandler.name}</span>
              </ButtonContainer>
            </Tooltip>
          ) : (
            <ButtonContainer onClick={buttonHandler.onClick} isValid={buttonHandler.isValid}>
              <span>{buttonHandler.name}</span>
            </ButtonContainer>
          ))}
      </ButtonsContainer>
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-y: scroll;
  width: ${(props) => (props.width ? props.width : 'calc(100% - 13px)')};
  background-color: ${(props) => props.backgroundColor || 'white'};
  border-radius: ${(props) => props.borderRadius || '8px'};
  margin: ${(props) => (props.margin ? props.margin : '5px 0')};
  box-shadow: ${(props) =>
    props.boxShadow ||
    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'};

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 0px;
    margin: 0px;
    box-shadow: none;
  }
`;

const BackButton = styled.div`
  position: absolute;
  display: flex;
  left: 15px;
  top: 15px;
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;

  > .MuiSvgIcon-root {
    color: black;
    font-size: 20px;
  }

  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
    > .MuiSvgIcon-root {
      color: white;
    }
  }
`;

const Title = styled.div`
  position: absolute;
  display: flex;
  left: 45px;
  top: 15px;
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 4px;
  padding: 5px 10px;

  > span {
    color: white;
    color: black;
    font-size: 12px;
    font-weight: 400;
  }
`;

const ButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 15px;
  top: 15px;
  /* background-color: red; */
`;

const ButtonContainer = styled.div`
  display: flex;
  background-color: ${(props) => (props.isValid ? props.theme.primaryColor : '#e8e8e8')};
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 15px;
  cursor: ${(props) => (props.isValid ? 'pointer' : 'not-allowed')};
  > span {
    color: ${(props) => (props.isValid ? 'white' : 'black')};
    font-size: 12px;
    font-weight: 300;
  }

  &:hover {
    background-color: ${(props) => (props.isValid ? props.theme.orangeColor : '#e8e8e8')};
    > span {
      color: ${(props) => (props.isValid ? 'white' : 'black')};
    }
  }
`;
