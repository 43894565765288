import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MessageIcon from '@mui/icons-material/Message';

import { orderBy } from '../../helpers/orderBy';
import LoadingScreen from '../generales/LoadingScreen';
import { convertTZ } from '../../helpers/convertTZ';
import PageLoadingError from '../generales/PageLoadingError';
import LicitacionesDetailMenuDInfoModal from './Licitaciones_detail_menuD_infoModal';
import LicitacionesDetailMenuDCommentModal from './Licitaciones_detail_menuD_commentModal';
import LicitacionesDetailMenuDFileModal from './Licitaciones_detail_menuD_fileModal';
import { nameHandler } from '../../helpers/openFileHandler';

export default function LicitacionesDetailMenuD({
  licitacion,
  offerDocs,
  offerDocsSections,
  offerDocsFiles,
  loading,
  error,
  files,
  comments,
  stage,
  isStageActive,
  periodoEntregaOfertas,
}) {
  const intl = useIntl();

  const [modalInfo, setModalInfo] = useState(false);
  const [modalComment, setModalComment] = useState(false);
  const [modalFile, setModalFile] = useState(false);

  const offerDocsOrdered = [];
  [
    ...offerDocsSections.filter((x) => (stage ? (x.stage || 'stage1') === stage.id : !x.stage)),
    {
      label: intl.formatMessage({
        id: 'app.licitaciones.detailMenuEa',
        defaultMessage: 'Without Section',
      }),
    },
  ].forEach((x) => {
    if (!x.idSeccion) {
      let data = [];
      offerDocs
        .filter((y) => !y.section || y.section === '')
        .forEach((y) => {
          const file = files.find((z) => z.idCategoria === y.idCategoria);
          const comment = comments.find((z) => z.idCategoria === y.idCategoria);
          const docsFiles = offerDocsFiles.filter((z) => z.idCategoria === y.idCategoria);
          data.push({ ...y, file, comment, docsFiles });
        });
      offerDocsOrdered.push({
        sectionName: x.label,
        data,
      });
    } else {
      let data = [];
      offerDocs
        .filter((y) => y.section === x.idSeccion)
        .forEach((y) => {
          const file = files.find((z) => z.idCategoria === y.idCategoria);
          const comment = comments.find((z) => z.idCategoria === y.idCategoria);
          const docsFiles = offerDocsFiles.filter((z) => z.idCategoria === y.idCategoria);
          data.push({ ...y, file, comment, docsFiles });
        });
      offerDocsOrdered.push({
        sectionName: x.label,
        data,
      });
    }
  });

  return loading ? (
    <ContentContainer>
      <LoadingScreen text={intl.formatMessage({ id: 'app.loadingdata' })} />
    </ContentContainer>
  ) : error ? (
    <ContentContainer>
      <PageLoadingError />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <InputContainer>
        <DataContainer>
          <span>
            <FormattedMessage
              id="app.licitaciones.configTitleH"
              defaultMessage={'app.licitaciones.configTitleH'}
            />
          </span>
          <DocumentsContainer>
            <div>
              <div>
                <span>{<FormattedMessage id="app.document" defaultMessage={'Name'} />}</span>
                <span>{<FormattedMessage id="app.comments" defaultMessage={'Comments'} />}</span>
                <span>{<FormattedMessage id="app.file" defaultMessage={'File'} />}</span>
                <span>{<FormattedMessage id="app.status" defaultMessage={'Status'} />}</span>
              </div>
            </div>

            {offerDocs.length === 0 ? (
              <p>
                <FormattedMessage id="app.licitaciones.configConceptsL" defaultMessage={''} />
              </p>
            ) : (
              offerDocsOrdered.map((y) => {
                if (y.data.length > 0) {
                  return (
                    <div key={y.sectionName}>
                      <span>{y.sectionName}</span>
                      <Spacer height={'8px'} />
                      {orderBy(y.data, 'codigo').map((x) => (
                        <div key={x.idCategoria}>
                          <Tooltip
                            title={
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ textDecoration: 'underline', marginBottom: '10px' }}>
                                  {x.label}
                                </span>
                                {(x.description || '').length > 0 && (
                                  <span style={{ marginBottom: '10px' }}>{x.description}</span>
                                )}
                                {x.docsFiles.length > 0 && (
                                  <span style={{ marginBottom: '10px' }}>
                                    {x.docsFiles.length} arhivos de ejemplo
                                  </span>
                                )}
                                <span style={{ fontStyle: 'italic', fontWeight: '600' }}>
                                  {x.required
                                    ? intl.formatMessage({ id: 'app.required' })
                                    : intl.formatMessage({ id: 'app.notRequired' })}
                                </span>
                              </div>
                            }
                          >
                            <span onClick={() => setModalInfo({ open: true, categoria: x })}>
                              {x.label}
                              {x.required ? ' (*)' : ''}
                            </span>
                          </Tooltip>
                          {x.comment ? (
                            <Tooltip title={x.comment.value}>
                              <MessageIconContainer has={true}>
                                <MessageIcon
                                  onClick={() => setModalComment({ open: true, categoria: x })}
                                />
                              </MessageIconContainer>
                            </Tooltip>
                          ) : (
                            <MessageIconContainer>
                              <MessageIcon
                                onClick={() => setModalComment({ open: true, categoria: x })}
                              />
                            </MessageIconContainer>
                            // <span onClick={() => setModalComment({ open: true, categoria: x })}>
                            //   --
                            // </span>
                          )}
                          {x.file ? (
                            <Tooltip title={nameHandler(x.file)}>
                              <MessageIconContainer has={true}>
                                <AttachFileIcon
                                  onClick={() => setModalFile({ open: true, categoria: x })}
                                />
                              </MessageIconContainer>
                            </Tooltip>
                          ) : (
                            <MessageIconContainer>
                              <AttachFileIcon
                                onClick={() => setModalFile({ open: true, categoria: x })}
                              />
                            </MessageIconContainer>
                            // <span onClick={() => setModalFile({ open: true, categoria: x })}>
                            //   --
                            // </span>
                          )}
                          <div>{x.file ? <CheckCircleIcon /> : <span>--</span>}</div>
                        </div>
                      ))}
                    </div>
                  );
                } else {
                  return null;
                }
              })
            )}
          </DocumentsContainer>
        </DataContainer>
        <Footer>
          <span>
            (*) = <FormattedMessage id="app.licitaciones.detailMenuDa" />
          </span>
        </Footer>
      </InputContainer>
      {modalInfo.open && (
        <LicitacionesDetailMenuDInfoModal
          open={modalInfo.open}
          onClose={() => setModalInfo(false)}
          licitacion={licitacion}
          categoria={modalInfo.categoria}
        />
      )}
      {modalComment.open && (
        <LicitacionesDetailMenuDCommentModal
          open={modalComment.open}
          onClose={() => setModalComment(false)}
          licitacion={licitacion}
          categoria={modalComment.categoria}
          isStageActive={isStageActive}
          periodoEntregaOfertas={periodoEntregaOfertas}
        />
      )}
      {modalFile.open && (
        <LicitacionesDetailMenuDFileModal
          open={modalFile.open}
          onClose={() => setModalFile(false)}
          licitacion={licitacion}
          categoria={modalFile.categoria}
          files={files}
          isStageActive={isStageActive}
          periodoEntregaOfertas={periodoEntregaOfertas}
        />
      )}
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgGray100};
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  > span:nth-child(1) {
    font-size: 14px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.cincoColor};
    font-weight: 500;
  }
  > span:nth-child(2) {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
  }
`;

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  > p {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
  }

  > div {
    > span {
      color: black;
      font-size: 14px;
    }
    > div {
      display: grid;
      grid-template-columns: 1fr 90px 90px 90px;
      grid-column-gap: 20px;
      border-bottom: 1px solid whitesmoke;
      cursor: pointer;

      > span {
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        color: black;
      }

      > span:nth-child(1) {
        text-align: left;
        cursor: pointer;
      }

      > div {
        display: flex;
        justify-content: center;

        > .MuiSvgIcon-root {
          font-size: 18px;
          /* color: darkgrey; */
          cursor: pointer;
        }
      }

      > div:nth-child(4) {
        cursor: default;
        > .MuiSvgIcon-root {
          color: limegreen;
          font-size: 18px;
          cursor: default;
        }
        > span {
          font-size: 14px;
          font-weight: 300;
          cursor: default;
        }
      }

      &:hover {
        background-color: whitesmoke;
      }
    }
    > div:last-child {
      margin-bottom: 20px;
    }
    > div:first-child {
      &:hover {
        background-color: transparent;
      }
    }
    > div:nth-child(2) {
      &:hover {
        background-color: transparent;
      }
    }
  }

  > div:nth-child(1) {
    > div {
      border-bottom: 1px solid lightgrey;
      margin-bottom: 20px;
      padding-bottom: 5px;
      cursor: default;

      > span {
        font-size: 13px;
        font-weight: 400;
        cursor: default;
      }
    }
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  cursor: default;
`;

const Footer = styled.div`
  > span {
    font-size: 12px;
    font-weight: 300;
  }
`;

const MessageIconContainer = styled.div`
  display: flex;
  justify-content: center;

  > .MuiSvgIcon-root {
    font-size: 18px;
    color: ${(props) => (props.has ? props.theme.cincoColor : 'darkgrey')};
    cursor: pointer;
  }
`;
