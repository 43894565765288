import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { read, utils, writeFile } from 'xlsx';

import LoadingScreen from '../generales/LoadingScreen';
import { orderBy } from '../../helpers/orderBy';
import { firestoreDate } from '../../helpers/firestoreDate';
import PageLoadingError from '../generales/PageLoadingError';
import LicitacionesDetailMenuCQaModal from './Licitaciones_detail_menuC_qaModal';
import { convertTZ } from '../../helpers/convertTZ';
import { Tooltip } from '@mui/material';
import LicitacionesDetailMenuCFileModal from './Licitaciones_detail_menuC_fileModal';
import SelectComponent from '../forms/SelectComponent';
import { dateToDate } from '../../helpers/dateToDate';
import { Edit } from '@mui/icons-material';

export default function LicitacionesDetailMenuC({
  licitacion,
  loading,
  qa,
  qaFiles,
  error,
  forceReload,
  isStageActive,
  stage,
  stageDates,
}) {
  const intl = useIntl();
  const [qaModal, setQaModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [fileModal, setFileModal] = useState(false);
  const [allQa, setAllQa] = useState({});
  const [all, setAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [categorySelected, setCategorySelected] = useState('');
  const [contracted, setContracted] = useState({});
  const [contractedAll, setContractedAll] = useState({});

  const qaOrder = [];
  (licitacion.qaCategories || []).forEach((x) => {
    const qaCat = qa.filter((y) => y.category === x.id);
    orderBy(qaCat, 'createdAt').forEach((y, index) => {
      qaOrder.push({ ...y, order: index + 1 });
    });
  });

  const onSearchHandler = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const onBlurHandler = (e) => {
    //dismiss keyboard
  };

  let textWithoutRegex = (searchText || '').replace(/[^a-zA-Zá-üÁ-Ü0-9 ]/g, '').toLowerCase();

  const qaSearch =
    searchText.length > 0
      ? qaOrder.filter((x) => x.question.toLowerCase().match(textWithoutRegex))
      : qaOrder;

  const onTime = !(stageDates?.questionEnd && stageDates?.questionStart)
    ? false
    : convertTZ(new Date(), 'America/Santiago') <
        convertTZ(new Date(stageDates?.questionEnd._seconds * 1000), 'America/Santiago') &&
      convertTZ(new Date(), 'America/Santiago') >
        convertTZ(new Date(stageDates?.questionStart._seconds * 1000), 'America/Santiago');
  // console.log({ onTime, stageDates, isStageActive });

  useEffect(() => {
    if (all) {
      setAllQa({});
    } else {
      let newAllQa = {};
      (licitacion.qaCategories || []).forEach((x) => {
        newAllQa = { ...newAllQa, [x.id]: true };
      });
      setAllQa(newAllQa);
    }
  }, [all]);

  useEffect(() => {
    if (contractedAll) {
      let newContractedAll = {};
      (licitacion.qaCategories || []).forEach((x) => {
        newContractedAll = { ...newContractedAll, [x.id]: true };
      });
      setContracted(newContractedAll);
    } else {
      setContracted({});
    }
  }, [contractedAll]);

  const downloadXlsx = () => {
    const workbook = utils.book_new();
    (licitacion.qaCategories || [])
      .filter((x) => (categorySelected.length > 0 ? x.id === categorySelected : true))
      .forEach((x) => {
        const worksheetArray = orderBy(
          [...qaSearch.filter((y) => y.category === x.id)],
          'createdAt'
        ).map((y, index) => {
          return {
            id: y.order,
            procedencia: y.owner
              ? intl.formatMessage({ id: 'app.licitaciones.qaE' })
              : intl.formatMessage({ id: 'app.licitaciones.qaF' }),
            categoria: x.label,
            fecha: new Date(y.createdAt._seconds * 1000),
            pregunta: y.question,
            respuesta: y.answer,
          };
        });
        const worksheet = utils.json_to_sheet(worksheetArray);
        utils.book_append_sheet(
          workbook,
          worksheet,
          x.label
            .replaceAll(':', ' ')
            .replaceAll('/', ' ')
            .replaceAll('?', ' ')
            .replaceAll('*', ' ')
            .replaceAll('[', ' ')
            .replaceAll(']', ' ')
            .substring(0, 30)
        );
      });
    writeFile(
      workbook,
      intl.locale === 'en'
        ? `Questions ${dateToDate(new Date()).dateHourEn}.xlsx`
        : `Preguntas ${dateToDate(new Date()).dateHour}.xlsx`,
      { compression: true }
    );
  };

  return loading ? (
    <ContentContainer>
      <LoadingScreen text={intl.formatMessage({ id: 'app.loadingdata' })} />
    </ContentContainer>
  ) : error ? (
    <ContentContainer>
      <PageLoadingError />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <SubHeaderContainer id="revision" searchText={(searchText || '').length > 0} all={all}>
        <div>
          <div>
            <SearchIcon />
            <input
              placeholder={intl.formatMessage({ id: 'app.search' })}
              value={searchText}
              onChange={(e) => onSearchHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
            />
            {searchText && searchText.length > 0 && (
              <CancelIcon onClick={() => setSearchText('')} />
            )}
          </div>
        </div>
        <div>
          <span>
            {
              qaSearch
                .filter((x) =>
                  categorySelected.length > 0 ? x.category === categorySelected : true
                )
                .filter((x) => (!all ? true : x.owner === true)).length
            }{' '}
            {qaSearch
              .filter((x) => (categorySelected.length > 0 ? x.category === categorySelected : true))
              .filter((x) => (!all ? true : x.owner === true)).length === 1
              ? intl.formatMessage({ id: 'app.question' })
              : intl.formatMessage({ id: 'app.licitaciones.gridC' })}
          </span>
          <div onClick={downloadXlsx}>
            <SimCardDownloadIcon />
          </div>
          <div>
            {contractedAll ? (
              <UnfoldMoreIcon onClick={() => setContractedAll(false)} />
            ) : (
              <UnfoldLessIcon onClick={() => setContractedAll(true)} />
            )}
          </div>
          <div onClick={() => setAll((prevState) => !prevState)}>
            <span>
              {all
                ? intl.formatMessage({ id: 'app.licitaciones.qaH' })
                : intl.formatMessage({ id: 'app.licitaciones.qaG' })}
            </span>
          </div>
          <div>
            <SelectComponent
              value={categorySelected}
              changeHandle={(e) => setCategorySelected(e.target.value)}
              items={licitacion.qaCategories || []}
              disabled={false}
              marginBottom={'0px'}
              inputLabel={''}
              selectNone={true}
              none={intl.formatMessage({ id: 'app.licitaciones.qaC' })}
              displayProps={{ style: { paddingBottom: 2, paddingTop: 2 } }}
            />
          </div>
        </div>
      </SubHeaderContainer>
      {(licitacion.qaCategories || [])
        .filter((x) => (categorySelected.length > 0 ? x.id === categorySelected : true))
        .map((x) => (
          <InputContainer complete={true}>
            <AddContainer onTime={onTime} allqa={allQa[x.id]}>
              <div onClick={() => setAllQa({ ...allQa, [x.id]: !allQa[x.id] })}>
                <span>
                  {allQa[x.id]
                    ? intl.formatMessage({ id: 'app.licitaciones.qaG' })
                    : intl.formatMessage({ id: 'app.licitaciones.qaH' })}
                </span>
              </div>
              {isStageActive && (
                <AddCircleIcon
                  onClick={onTime ? () => setQaModal({ open: true, category: x.id }) : null}
                />
              )}
            </AddContainer>
            <IconContainer
              onClick={() => setContracted({ ...contracted, [x.id]: !contracted[x.id] })}
            >
              {!contracted[x.id] ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
            </IconContainer>
            <DataContainer italic={true}>
              <div>
                <span>
                  {x.label} (
                  {
                    qaSearch
                      .filter((y) => y.category === x.id)
                      .filter((y) => (allQa[x.id] ? true : y.owner === true)).length
                  }
                  )
                </span>
              </div>
              {(allQa[x.id] ? qaSearch : qaSearch.filter((z) => z.owner)).filter(
                (y) => y.category === x.id
              ).length > 0 &&
                contracted[x.id] && (
                  <ConsultaContainer>
                    <Header>
                      <span>id</span>
                      <span>
                        <FormattedMessage id="app.detail" />
                      </span>
                      <span>
                        <FormattedMessage id="app.date" />
                      </span>
                    </Header>
                    {(allQa[x.id] ? qaSearch : qaSearch.filter((z) => z.owner)).filter(
                      (y) => y.category === x.id
                    ).length === 0 ? (
                      <span>
                        <FormattedMessage
                          id="app.licitaciones.qaB"
                          defaultMessage={'No questions added'}
                        />
                      </span>
                    ) : (
                      orderBy(
                        [...(allQa[x.id] ? qaSearch : qaSearch.filter((z) => z.owner))],
                        'createdAt'
                      )
                        .filter((y) => y.category === x.id)
                        .map((y, index) => {
                          const files = qaFiles.filter((z) => z.idQa === y.idQa);
                          return (
                            <div key={y.idQa}>
                              <Question
                                owner={y.owner}
                                selected={selected[y.idQa]}
                                onClick={() =>
                                  setSelected((prevState) => {
                                    return {
                                      ...prevState,
                                      [y.idQa]: !prevState[y.idQa],
                                    };
                                  })
                                }
                              >
                                <span>{y.order}</span>
                                {selected[y.idQa] ? (
                                  <div>
                                    <div>
                                      <span>
                                        {y.owner
                                          ? intl.formatMessage({ id: 'app.licitaciones.qaE' })
                                          : intl.formatMessage({ id: 'app.licitaciones.qaF' })}
                                      </span>
                                    </div>
                                    <span>{y.question}</span>
                                    {y.owner && onTime && (
                                      <Edit onClick={() => setQaModal({ open: true, qa: y })} />
                                    )}
                                  </div>
                                ) : (
                                  <Tooltip title={y.question}>
                                    <div>
                                      <div>
                                        <span>
                                          {y.owner
                                            ? intl.formatMessage({ id: 'app.licitaciones.qaE' })
                                            : intl.formatMessage({ id: 'app.licitaciones.qaF' })}
                                        </span>
                                      </div>
                                      <span>{y.question}</span>
                                      {y.owner && onTime && (
                                        <Edit onClick={() => setQaModal({ open: true, qa: y })} />
                                      )}
                                    </div>
                                  </Tooltip>
                                )}
                                {y.answerDate ? <CheckCircleIcon /> : <span></span>}
                                <span>{firestoreDate(y.createdAt, true, intl.locale)}</span>
                              </Question>
                              {selected[y.idQa] && (
                                <Answer answer={y.answerDate} files={files.length > 0}>
                                  <span></span>
                                  <div>
                                    <span>
                                      {y.answerDate
                                        ? y.answer
                                        : intl.formatMessage({ id: 'app.licitaciones.qaD' })}
                                    </span>
                                    <div>
                                      {files.length > 0 && (
                                        <div onClick={() => setFileModal({ open: true, files })}>
                                          <AttachFileIcon />
                                          <span>{files.length}</span>
                                        </div>
                                      )}
                                      {y.answerDate && (
                                        <div>
                                          <CalendarMonthIcon />
                                          <span>{firestoreDate(y.answerDate)}</span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Answer>
                              )}
                            </div>
                          );
                        })
                    )}
                  </ConsultaContainer>
                )}
            </DataContainer>
          </InputContainer>
        ))}
      {qaModal.open && (
        <LicitacionesDetailMenuCQaModal
          open={qaModal.open}
          onClose={() => setQaModal(false)}
          licitacion={licitacion}
          forceReload={forceReload}
          categorySelected={qaModal.category}
          stage={stage}
          qa={qaModal.qa}
        />
      )}
      {fileModal.open && (
        <LicitacionesDetailMenuCFileModal
          open={fileModal.open}
          onClose={() => setFileModal(false)}
          files={fileModal.files}
        />
      )}
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgGray100};
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => (props.complete ? 'whitesmoke' : 'rgba(0,0,0,0.02)')};
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    > span {
      font-size: 14px;
      color: ${(props) => props.theme.cincoColor};
      font-weight: 500;
    }
  }
  > span {
    font-size: 14px;
    font-weight: 300;
    font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  }
`;

const ConsultaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  > span {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 150px;
  grid-column-gap: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgrey;

  > span {
    font-size: 13px;
    font-weight: 400;
  }
`;
const Question = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 20px 150px;
  grid-column-gap: 20px;
  padding: 3px 0;
  border-bottom: ${(props) => (props.selected ? 'none' : '1px solid whitesmoke')};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;

  > span {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  > div {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    > span {
      font-size: 14px;
      font-weight: 300;
      color: black;
    }
    > div {
      display: flex;
      align-items: center;
      background-color: ${(props) => (props.owner ? props.theme.cincoColor : 'darkgrey')};
      padding: 0px 3px;
      margin-right: 10px;
      border-radius: 4px;
      > span {
        font-size: 8px;
        color: white;
      }
    }
    > span {
      white-space: ${(props) => (props.selected ? 'normal' : 'nowrap')};
      overflow: ${(props) => (props.selected ? 'auto' : 'hidden')};
      text-overflow: ${(props) => (props.selected ? 'none' : 'ellipsis')};
      color: black;
    }
    > .MuiSvgIcon-root {
      margin-left: 10px;
      font-size: 18px;
      color: ${(props) => props.theme.orangeColor};

      :hover {
        color: grey;
      }
    }
  }
  > .MuiSvgIcon-root {
    font-size: 20px;
    color: limegreen;
  }
  > span:nth-child(4) {
    font-size: 13px;
    color: black;
  }
  &:hover {
    background-color: whitesmoke;
  }
`;

const Answer = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-column-gap: 20px;
  padding: 10px 0 15px 0;
  border-bottom: 1px solid whitesmoke;

  > div {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 4px;
    background-color: whitesmoke;

    > span {
      font-size: ${(props) => (props.answer ? '14px' : '12px')};
      font-weight: 300;
      font-style: ${(props) => (props.answer ? 'normal' : 'italic')};
      color: black;
    }

    > div {
      display: ${(props) => (props.answer || props.files ? 'flex' : 'none')};
      margin: 15px 0px 0 0;
      > div {
        display: flex;
        align-items: center;
        margin-right: 30px;
        cursor: pointer;
        > .MuiSvgIcon-root {
          font-size: 17px;
          color: grey;
          margin-right: 5px;
        }
        > span {
          font-size: 12px;
          font-weight: 300;
          color: black;
        }
      }
    }
  }
`;

const AddContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 55px;
  display: flex;
  align-items: center;

  > div {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${(props) => (props.allqa ? props.theme.cincoColor : 'darkgrey')};
    padding: 2px 5px;
    cursor: pointer;
    > span {
      color: white;
      font-size: 10px;
    }
  }

  > .MuiSvgIcon-root {
    color: ${(props) => (props.onTime ? props.theme.orangeColor : '#e8e8e8')};
    cursor: ${(props) => (props.onTime ? 'pointer' : 'not-allowed')};
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  height: 10px;

  > div:nth-child(1) {
    display: flex;
    width: 100%;
    max-width: 350px;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ced4da;

    > div {
      display: flex;
      align-items: center;
      width: 100%;

      > .MuiSvgIcon-root:nth-child(1) {
        color: ${(props) => (props.searchText ? 'dimgrey' : 'darkgrey')};
        font-size: 18px;
      }

      > .MuiSvgIcon-root:nth-child(3) {
        color: dimgrey;
        cursor: pointer;
        font-size: 18px;
      }

      > input {
        border: none;
        width: 100%;
        outline-width: 0;
        font-size: medium;
        background-color: transparent;
        padding-left: 10px;
        color: dimgrey;
        font-size: 14px;
        font-weight: 300;

        &::placeholder {
          color: ${(props) => (props.searchText ? 'dimgrey' : 'darkgrey')};
          font-weight: 300;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  > div:nth-child(2) {
    display: flex;
    align-items: center;

    > span {
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
    }

    > div:nth-child(2) {
      display: flex;
      margin-left: 20px;
      background-color: white;
      border: 1px solid #ced4da;
      border-radius: 4px;
      padding: 5px;
      cursor: pointer;

      > .MuiSvgIcon-root {
        font-size: 18px;
        color: grey;
      }
    }

    > div:nth-child(3) {
      display: flex;
      margin-left: 10px;
      background-color: white;
      border: 1px solid #ced4da;
      border-radius: 4px;
      padding: 5px;
      cursor: pointer;

      > .MuiSvgIcon-root {
        font-size: 18px;
      }
    }

    > div:nth-child(4) {
      margin-left: 10px;
      display: flex;
      align-items: center;
      background-color: ${(props) => (props.all ? 'darkgrey' : props.theme.cincoColor)};
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;

      > span {
        color: white;
        font-size: 13px;
        font-weight: 300;
      }
    }

    > div:nth-child(5) {
      margin-left: 10px;
      background-color: white;
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;

  > .MuiSvgIcon-root {
    color: lightgrey;
    cursor: pointer;

    :hover {
      color: dimgrey;
    }
  }
`;
