import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import NoTableRecordToDisplay from '../generales/NoTableRecordToDisplay';
import Countdown from '../generales/CuentaRegresiva';

export default function LicitacionesGrid({ licitaciones = [], loading }) {
  const intl = useIntl();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [preparingData, setPreparingData] = useState(true);

  // console.log({ licitaciones });

  useEffect(() => {
    setData(
      licitaciones.map((x) => {
        const hasActiveStage = (x.stages || []).find((y) => y.id === (x.stageActive || 'stage1'));
        return {
          idLicitacion: x.idLicitacion,
          clienteName: x.clienteName,
          name: x.name,
          questionEnd: hasActiveStage ? x.questionEnd : null,
          offerEnd: hasActiveStage ? x.offerEnd : null,
          stages: (x.stages || []).length > 0 ? (x.stages || []).length : '--',
          stagesRaw: x.stages,
        };
      })
    );
    setPreparingData(false);
  }, [licitaciones]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'clienteName',
        header: intl.formatMessage({ id: 'app.company' }),
        size: 150,
        grow: true,
        enableGrouping: false,
        muiTableHeadCellProps: { align: 'left' },
        muiTableBodyCellProps: { align: 'left' },
      },
      {
        accessorKey: 'name',
        header: intl.formatMessage({ id: 'app.licitaciones.gridA' }),
        size: 250,
        grow: true,
        enableGrouping: false,
        muiTableHeadCellProps: { align: 'left' },
        muiTableBodyCellProps: { align: 'left' },
      },
      {
        accessorKey: 'stages',
        header: intl.formatMessage({ id: 'app.stages' }),
        enableGrouping: false,
        size: 120,
        grow: false,
        muiTableHeadCellProps: { align: 'left' },
        muiTableBodyCellProps: { align: 'left' },
      },
      {
        accessorKey: 'questionEnd',
        header: intl.formatMessage({ id: 'app.licitaciones.gridC' }),
        maxSize: 150,
        minSize: 150,
        grow: false,
        enableGrouping: false,
        muiTableHeadCellProps: { align: 'left' },
        muiTableBodyCellProps: { align: 'left' },
        Cell: ({ cell, row }) => <Countdown targetDate={cell.getValue()} />,
      },
      {
        accessorKey: 'offerEnd',
        header: intl.formatMessage({ id: 'app.licitaciones.menuD' }),
        maxSize: 150,
        minSize: 150,
        grow: false,
        enableGrouping: false,
        muiTableHeadCellProps: { align: 'left' },
        muiTableBodyCellProps: { align: 'left' },
        Cell: ({ cell, row }) => <Countdown targetDate={cell.getValue()} />,
      },
    ],
    [intl]
  );

  return (
    <SubDataGridContainer>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableBottomToolbar={false}
        enableColumnResizing={true}
        enableGlobalFilterModes={true}
        enablePagination={false}
        enableColumnPinning={true}
        enableRowNumbers={false}
        enableRowVirtualization={false}
        enableGrouping={true}
        enableColumnDragging={false}
        enableColumnOrdering={false}
        enableSorting={true}
        enableDensityToggle={false}
        layoutMode="grid"
        localization={
          intl.locale === 'en'
            ? {
                ...MRT_Localization_EN,
                groupedBy: '',
                noRecordsToDisplay: <NoTableRecordToDisplay />,
              }
            : {
                ...MRT_Localization_ES,
                groupedBy: '',
                filterByColumn: 'Buscar',
                noRecordsToDisplay: <NoTableRecordToDisplay />,
              }
        }
        initialState={{
          density: 'compact',
          expanded: true,
          grouping: [],
          isLoading: loading,
          sorting: [
            {
              id: 'clienteName',
              desc: true,
            },
          ],
        }}
        state={{
          showProgressBars: false,
          isLoading: preparingData,
        }}
        muiCircularProgressProps={{
          color: 'inherit',
        }}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: '400',
            fontSize: '13px',
            borderRight: '1px solid #e0e0e0', //add a border between columns
          },
          size: 'small',
          // padding: 'none',
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          sx: {
            borderRadius: '8px',
            // border: '1px solid lightgrey',
          },
        }}
        muiTopToolbarProps={{
          variant: 'dense',
          sx: {
            // background: 'green',
            borderBottom: '1px solid whitesmoke',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          },
        }}
        muiToolbarAlertBannerProps={{
          sx: {
            background: 'white',
          },
        }}
        muiTableContainerProps={{
          sx: {
            overflowY: 'auto',
            height: 'calc(100% - 55px)',
          },
        }}
        muiTableBodyCellProps={({ row, column }) => {
          return {
            size: 'small',
            onClick: () => {
              if (row.subRows.length === 0 && (row.original.stagesRaw || []).length > 0) {
                navigate(`/licitaciones/${row.original.idLicitacion}/detail`);
              }
            },
            sx: {
              background: row?.subRows.length > 0 ? 'whitesmoke' : 'none',
              // color: row?.subRows.length > 0 ? 'black' : '#414141',
              color: row?.subRows.length > 0 ? 'black' : 'black',
              borderRight: '1px solid #e0e0e0',
              fontSize: '14px',
              fontWeight: 300,
              fontFamily: '"Inter", sans-serif',
              padding:
                column.id === 'createdBy' ||
                column.id === 'responsibles' ||
                column.id === 'participants' ||
                column.id === 'viewers'
                  ? '0px'
                  : '8px',
            },
          };
        }}
        muiTableBodyRowProps={({ row }) => {
          return {
            sx: {
              height: row?.subRows.length > 0 ? '30px' : '30px',
              cursor: (row.original.stagesRaw || []).length > 0 ? 'pointer' : 'not-allowed',
            },
          };
        }}
        muiToolbarAlertBannerChipProps={{
          size: 'small',
          // disabled: true,
        }}
        muiTableFooterRowProps={{
          sx: {
            background: 'transparent',
          },
        }}
        muiTableFooterCellProps={{
          sx: {
            borderBottom: 'none',
          },
        }}
      />
    </SubDataGridContainer>
  );
}

const SubDataGridContainer = styled.div`
  display: grid;
  position: absolute;
  top: 25px;
  bottom: 25px;
  left: 25px;
  right: 25px;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
