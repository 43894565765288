import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import LoadingScreen from '../generales/LoadingScreen';
import LicitacionesDetailMenuBFiles from './Licitaciones_detail_menuB_files';
import PageLoadingError from '../generales/PageLoadingError';

export default function LicitacionesDetailMenuB({ folders, files, loading, error }) {
  // console.log({ folders, files });
  const intl = useIntl();
  return loading ? (
    <ContentContainer>
      <LoadingScreen text={intl.formatMessage({ id: 'app.loadingdata' })} />
    </ContentContainer>
  ) : error ? (
    <ContentContainer>
      <PageLoadingError />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <InputFileContainer>
        <LicitacionesDetailMenuBFiles foldersData={folders} filesData={files} />
      </InputFileContainer>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgGray100};

  @media (max-width: 500px) {
    padding: 0px;
    background-color: white;
  }
`;

const InputFileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 8px;
  height: 100%;

  @media (max-width: 500px) {
    box-shadow: none;
  }
`;
